
.Toast > .Toastify__toast{
    background:#363636;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;

    .Toastify__toast-body{
        color: #fff
    }

    .Toastify__close-button{
        opacity: 1;
        align-self: auto;

        & > svg {
            width: 22px;
            height: 22px;
            color: #fff
        }
    }
}

